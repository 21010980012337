<template>
  <div class="modal fade" id="viewVenueModal" data-toggle="modal">
    <div
      class="modal-dialog"
      :class="venue && venue.photos_link != null ? 'modal-lg' : ''"
    >
      <div class="modal-content" v-if="venue">
        <div class="modal-header">
          <h5 class="modal-title" id="viewVenueModal">{{ venue.full_name }}</h5>

          <!--begin::Close-->
          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
          <!--end::Close-->
        </div>

        <div class="modal-body">
          <div class="card">
            <div class="card-body p-9">
              <div class="row flex-column-reverse flex-lg-row">
                <div
                  :class="
                    venue.photos_link != null ? 'col-12 col-lg-6 mt-5 mt-lg-0' : 'col-12'
                  "
                >
                  <div class="row mb-7">
                    <label class="col-lg-4 fw-bold text-muted">Country</label>
                    <div class="col-lg-8">
                      <span class="fw-bolder fs-6 text-gray-800">{{
                        venue.country.title
                      }}</span>
                    </div>
                  </div>
                  <div class="row mb-7">
                    <label class="col-lg-4 fw-bold text-muted">City</label>
                    <div class="col-lg-8">
                      <span class="fw-bolder fs-6 text-gray-800">{{
                        venue.city
                      }}</span>
                    </div>
                  </div>
                  <div class="row mb-7">
                    <label class="col-lg-4 fw-bold text-muted">Address</label>
                    <div class="col-lg-8">
                      <a
                        :href="mapsLink"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="This will open a new tab."
                        target="_blank"
                        rel="noopener noreferrer"
                        class="fw-bolder fs-6 text-gray-800 text-decoration-underline"
                        >{{ venue.address }}</a
                      >
                    </div>
                  </div>
                  <div class="row mb-7">
                    <label class="col-lg-4 fw-bold text-muted">Zip code</label>
                    <div class="col-lg-8">
                      <span
                        class="fw-bolder fs-6"
                        :class="venue.zip ? 'text-gray-800' : 'text-muted'"
                        >{{ venue.zip ? venue.zip : "Nothing entered" }}</span
                      >
                    </div>
                  </div>
                  <div class="row mb-7">
                    <label class="col-lg-4 fw-bold text-muted"
                      >Created at</label
                    >
                    <div class="col-lg-8">
                      <span class="fw-bolder fs-6 text-gray-800">{{
                        venue.created_at
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6" v-if="venue.photos_link != null">
                  <div
                    v-if="venue.photos_link.length > 1"
                    id="image_carousel"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div class="carousel-inner">
                      <div
                        class="carousel-item"
                        :class="index == 0 ? 'active' : ''"
                        v-for="(image, index) in venue.photos_link"
                        :key="index"
                      >
                        <Img :src="image" class="d-block w-100"></Img>
                      </div>
                    </div>
                    <button
                      class="carousel-control-prev"
                      type="button"
                      data-bs-target="#image_carousel"
                      data-bs-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                      class="carousel-control-next"
                      type="button"
                      data-bs-target="#image_carousel"
                      data-bs-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                  <Img v-else :src="venue.photos_link[0]"></Img>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import Img from "@/components/general/Img.vue"

export default defineComponent({
  name: "view-venue-modal",
  components:{
    Img
  },
  props: {
    venue: Object
  },
  computed: {
    mapsLink() {
      if(this.venue.meeting_point) {
        return `https://www.google.com/maps?q=${this.venue.meeting_point}`;
      }
      return null;
    }
  }
});
</script>

<style scoped>
img {
  width: 100% !important;
  height: 230px !important;
}
</style>

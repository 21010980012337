<template>
  <img :src="src" />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Img",
  props: {
    src: String,
    alt: String
  }
});
</script>

<template>
  <GenerateCrewRosterModal ref="crewModal" />
  <ViewVenueModal :venue="jobVenue"></ViewVenueModal>
  <div class="d-flex flex-column w-100">
    <Alert
      icon-class="fa fa-warning"
      type="info"
      v-if="
        pinnedNotes.length && ($can('job_notes_access', 'all') || isCrewBoss)
      "
    >
      <template v-slot:icon>
        <IconBulletList />
      </template>
      <template v-slot:content>
        <div class="d-flex flex-column">
          <h3 class="text-info">Job notes</h3>
          <ul class="m-0">
            <li v-for="note in pinnedNotes" :key="note.id">
              {{ note.comment }}
            </li>
          </ul>
        </div>
      </template>
    </Alert>
    <div class="card mb-5 mb-xxl-8" v-if="item != null">
      <div class="card-body pt-9 pb-0">
        <div
          class="d-flex flex-wrap flex-sm-nowrap mb-3 justify-content-center justify-content-md-start"
        >
          <div class="flex-grow-1">
            <div
              class="d-flex justify-content-between align-items-start flex-wrap mb-2"
            >
              <div class="d-flex flex-column">
                <div class="row">
                  <div class="d-flex flex-column flex-md-row col-12 col-md-3">
                    <div
                      class="d-flex flex-column align-self-center align-self-md-start"
                    >
                      <a href="#" class="text-gray-800 fs-2 fw-bolder me-1">{{
                        item.name
                      }}</a>
                      <a class="text-gray-400 me-5 mb-2">
                        <span class="text-gray-800 fw-bolder me-2">
                          Invoice ref:
                        </span>
                        {{ item.invoice_reference }}
                      </a>
                    </div>
                  </div>
                  <div class="col-12 col-md-9 col-lg-12 col-xl-9 pe-0 my-3 my-md-0">
                    <div
                      class="d-flex w-100 py-0 py-lg-3 py-xl-0 justify-content-between flex-sm-row flex-column"
                    >
                      <JobStatusButton
                        class="w-100 w-sm-auto min-w-95px mb-3 mb-sm-0 job-status-button align-self-center align-self-md-start"
                        :job="item"
                        :jobsStatusFilter="item.is_past ? 'past' : 'upcoming'"
                        :full-width="false"
                        :is-single-job-view="true"
                      ></JobStatusButton>
                      <div class="d-flex flex-column">
                        <div class="d-flex flex-column flex-sm-row align-items-center justify-content-end gap-3 mb-3">
                          <DownloadSalaryReportForJob
                            :job-id="item.id"
                            class="float-end static-button-height mx-2"
                            v-if="item && $can('salary_access', 'all')"
                          />
                          <button
                            :disabled="loadingJobChat"
                            v-if="$can('can_use_job_chat', 'all')"
                            @click="handleAdminJobChat()"
                            class="cursor-pointer w-100 w-sm-auto btn min-w-115px btn-sm btn-success float-end static-button-height"
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              v-if="loadingJobChat"
                            ></span>
                            <i class="fas fa-comment fs-4" v-else></i> Job Chat
                          </button>
                          <button
                            v-if="$can('job_edit', 'all')"
                            @click="$router.push(`/jobs/${item.id}/edit`)"
                            :class="
                              $can('can_download_job_report', 'all')
                                ? 'ms-md-0'
                                : 'ms-md-3'
                            "
                            type="button"
                            class="btn btn-sm w-100 w-sm-auto btn-warning float-end static-button-height"
                          >
                            Edit
                          </button>
                          <button
                            @click="$router.push('/dashboard')"
                            type="button"
                            class="btn btn-sm min-w-85px btn-primary d-none d-md-block float-end static-button-height"
                          >
                            <span class="fas fa-chevron-left me-3"></span>Back
                          </button>

                        </div>
                        <div class="d-flex flex-column flex-sm-row align-items-center justify-content-end gap-3">
                          <button
                            v-if="$can('can_download_job_report', 'all')"
                            @click="handleJobReport(item.id)"
                            :disabled="isDownloadingReport"
                            type="button"
                            style="min-width: 105px !important;"
                            class="btn btn-sm w-100 w-sm-auto btn-warning  float-end static-button-height"
                          >
                            <span
                              v-if="isDownloadingReport"
                              class="spinner-border spinner-border-sm"
                            ></span>
                            <span class="d-block d-sm-none">Report</span>
                            <span class="d-none d-sm-block">Job Report</span>
                          </button>
                          <CrewListActions
                            class="float-end static-button-height w-100 w-sm-auto"
                          ></CrewListActions>
                          <DownloadRoster
                            @open-client-modal="handleClientModal"
                            v-if="
                              item && $can('can_download_crew_roster', 'all')
                            "
                            :job-ids="[item.id]"
                            class="float-end static-button-height"
                          ></DownloadRoster>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  <div class="row mb-md-1 mb-0">
                    <div class="col-12 col-md-6 mb-md-1 mb-0">
                      <router-link
                        :to="'/client/' + item.company.id"
                        v-if="item.company"
                        class="text-gray-400 text-hover-primary me-5"
                      >
                        <span class="text-gray-800 fw-bolder me-2">
                          Client:
                        </span>
                        {{ item.company.name }}
                      </router-link>
                    </div>
                    <div class="col-12 col-md-6 mb-md-1 mb-0">
                      <a
                        v-if="item.book_from_branches"
                        class="text-gray-400 me-5"
                      >
                        <span class="text-gray-800 fw-bolder me-2">
                          {{
                            item.book_from_branches &&
                            item.book_from_branches.length > 1
                              ? "Branches:"
                              : "Branch:"
                          }}
                        </span>
                        {{ formatJobBranches() }}
                      </a>
                    </div>
                  </div>
                  <div class="row mb-md-1 mb-0">
                    <div class="col-12 col-md-6 mb-md-1 mb-0">
                      <a
                        v-if="item.shifts.length > 1"
                        class="text-gray-400 me-5"
                      >
                        <span class="text-gray-800 fw-bolder me-2">
                          First Shift:
                        </span>
                        {{ dateFirstShift }}
                      </a>
                    </div>
                    <div class="col-12 col-md-6 mb-md-1 mb-0">
                      <a
                        v-if="item.shifts.length > 1"
                        class="text-gray-400 me-5"
                      >
                        <span class="text-gray-800 fw-bolder me-2">
                          Last Shift:
                        </span>
                        {{ dateLastShift }}
                      </a>
                    </div>
                  </div>
                  <div class="row mb-md-1 mb-0">
                    <div class="col-12 col-md-6 mb-md-1 mb-0">
                      <a
                        v-if="item.venue"
                        class="text-gray-400 text-hover-primary me-5 cursor-pointer"
                        data-bs-target="#viewVenueModal"
                        data-bs-toggle="modal"
                        @click="showJobVenueModal()"
                      >
                        <span class="text-gray-800 fw-bolder me-2">
                          Venue:
                        </span>
                        {{ item.venue.name }}
                      </a>
                    </div>
                    <div class="col-12 col-md-6 mb-md-1 mb-0">
                      <a class="text-gray-400 me-5">
                        <span class="text-gray-800 fw-bolder me-2">
                          Entrance:
                        </span>
                        {{
                          item.venue && item.venue.entrance
                            ? item.venue.entrance
                            : "Not Defined"
                        }}
                      </a>
                    </div>
                    <div
                      class="col-12 col-md-6 mt-1"
                      v-if="item.contact_on_location"
                    >
                      <router-link
                        :to="
                          '/users/' + item.contact_on_location.id + '/personal'
                        "
                        class="text-gray-400 text-hover-primary me-5"
                      >
                        <span class="text-gray-800 fw-bolder me-2">
                          COL:
                        </span>
                        {{ item.contact_on_location.name }}
                      </router-link>
                    </div>
                  </div>
                  <div class="row mb-md-1 mb-0">
                    <div class="col-12 col-md-6 mb-md-1 mb-0">
                      <a class="text-gray-400 me-5">
                        <span class="text-gray-800 fw-bolder me-2">
                          Address:
                        </span>
                        {{ getAddress }}
                      </a>
                    </div>
                    <div class="col-12 col-md-6 mb-md-1 mb-0">
                      <a class="text-gray-400 me-5">
                        <span class="text-gray-800 fw-bolder me-2">
                          Details:
                        </span>
                        {{ item.details }}
                      </a>
                    </div>
                  </div>
                  <div class="row mb-md-1 mb-0">
                    <div class="col-md-6 mb-md-1 mb-0 d-none d-md-block"></div>
                    <div class="col-12 col-md-6 mb-md-1 mb-0">
                      <router-link
                        :to="'/users/' + item.created_by.id"
                        v-if="item.created_by"
                        class="text-gray-400 text-hover-primary me-5"
                      >
                        <span class="text-gray-800 fw-bolder me-2">
                          Job created by:
                        </span>
                        {{ item.created_by.name }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex overflow-auto h-55px" v-if="!loading">
          <ul
            class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
          >
            <li class="nav-item">
              <router-link
                :to="`/jobs/${$route.params.id}/shifts`"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Shifts
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="
                $can('jobs_book_workers', 'all') &&
                  !$can('can_request_worker', 'all')
              "
            >
              <router-link
                :to="`/jobs/${$route.params.id}/book`"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Book
              </router-link>
            </li>
            <li
              v-if="$can('can_view_additional_costs', 'all')"
              class="nav-item"
            >
              <router-link
                :to="`/jobs/${$route.params.id}/additional-costs`"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Additional costs
              </router-link>
            </li>
            <li v-if="$can('can_view_job_projection', 'all')" class="nav-item">
              <router-link
                :to="`/jobs/${$route.params.id}/job-projection`"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Job projection
              </router-link>
            </li>
            <li v-if="$can('rate_access', 'all')" class="nav-item">
              <router-link
                :to="`/jobs/${$route.params.id}/client-rates`"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Client rates
              </router-link>
            </li>
            <li v-if="$can('rate_access', 'all')" class="nav-item">
              <router-link
                :to="`/jobs/${$route.params.id}/worker-rates`"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Worker rates
              </router-link>
            </li>
            <li
              v-if="$can('rate_access', 'all') || isCrewBoss"
              class="nav-item"
            >
              <router-link
                :to="`/jobs/${$route.params.id}/job-notes`"
                class="nav-link text-active-primary me-6"
                active-class="active"
              >
                Job notes
              </router-link>
            </li>
          </ul>
          <div
            class="ms-auto align-self-center form-check form-switch"
            v-if="item.shifts.length > 0"
          >
            <input
              class="form-check-input"
              type="checkbox"
              id="toggleShifts"
              unchecked
              v-model="allShiftsAreExpanded"
              @change="setAllShiftsAreExpanded()"
            />
            <label
              class="form-check-label fs-5 fw-bold text-nowrap"
              for="toggleShifts"
              >Expand All Shift Boxes</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import JobStatusButton from "@/components/dashboard/JobStatusButton.vue";
import ViewVenueModal from "@/components/venues/modal/ViewVenueModal.vue";
import Swal from "sweetalert2";
import moment from "moment";
import { eventBus } from "@/pusher.js";
import Alert from "@/components/general/Alert.vue";
import IconBulletList from "@/components/Icons/IconBulletList.vue";
import { isCrewBoss } from "@/components/jobs/helpers";
import CrewListActions from "@/components/jobs/CrewListActions.vue";
import DownloadRoster from "@/components/roster/DownloadRoster.vue";
import GenerateCrewRosterModal from "@/components/modals/GenerateCrewRosterModal.vue";
import DownloadSalaryReportForJob from "@/components/salary-reports/DownloadSalaryReportForJob.vue";

export default defineComponent({
  name: "job-shifts-card",
  components: {
    DownloadSalaryReportForJob,
    GenerateCrewRosterModal,
    DownloadRoster,
    CrewListActions,
    IconBulletList,
    Alert,
    JobStatusButton,
    ViewVenueModal
  },
  data() {
    return {
      jobVenue: null,
      allShiftsAreExpanded: false,
      isDownloadingReport: false,
      modal: null,
      key: 0,
      loadingJobChat: false
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    ...mapGetters("JobModule", ["item", "loading"]),
    pinnedNotes() {
      if (this.item && this.item.notes) {
        return this.item.notes.filter(note => note.is_pinned);
      }
      return [];
    },
    isCrewBoss() {
      if(this.currentUser && this.item) {
        return isCrewBoss(this.item);
      }
      return false;
    },
    dateFirstShift() {
      if (this.item.shifts.length > 0){
        return moment(this.item.shifts[0].start, "DD/MM/YYYY").format("ddd DD/MM/YYYY");
      }
      return null;
    },
    dateLastShift() {
      if (this.item.shifts.length > 1){
        return moment(this.item.shifts[this.item.shifts.length - 1].start, "DD/MM/YYYY").format("ddd DD/MM/YYYY");
      }
      return null;
    },
    getAddress() {
      const addressArray = [];
      if (this.item.venue) {
        if (this.item.venue.address !== null) {
          addressArray.push(this.item.venue.address);
        }
        if (this.item.venue.city !== null) {
          addressArray.push(this.item.venue.city);
        }
        if (this.item.venue.zip !== null) {
          addressArray.push(this.item.venue.zip);
        }
        if (this.item.venue.province !== null) {
          addressArray.push(this.item.venue.province);
        }
        if (this.item.venue.country !== null) {
          addressArray.push(this.item.venue.country.title);
        }
        if (addressArray.length > 0) {
          return addressArray.join(', ');
        }
      }

      return '';
    },
  },
  methods: {
    ...mapActions("JobModule", ["openAdminJobChat", "setAllShiftsAreExpanded", "downloadJobReport", "downloadCrewlistPDF"]),
    handleClientModal() {
      this.$refs.crewModal.toggleModal(true, this.item.company.id);
    },
    formatJobBranches() {
      let output = "";
      if (this.item.book_from_branches.length === 1) {
        return this.item.book_from_branches[0].name;
      } else if (this.item.book_from_branches.length > 1) {
        for (let i = 0; i < this.item.book_from_branches.length; i++) {
          if (i !== this.item.book_from_branches.length -1) {
            output += `${this.item.book_from_branches[i].name}, `;
          } else {
            output += `${this.item.book_from_branches[i].name} `;
          }
        }
        return output;
      }
    },
    handleAdminJobChat() {
      this.loadingJobChat = true;
      if(this.item) {
        this.openAdminJobChat(this.item.id).then((chatId) => {
          eventBus.emit("openAdminJobChat", chatId);
          this.loadingJobChat = false;
        })
          .catch(() => {
            // At no point should we reach this catch
            // but in such cases the error will be logged
            // in the backend for crazy edge cases
            this.loadingJobChat = true;
          });
      }
    },
    showJobVenueModal() {
      this.jobVenue = this.item.venue;
    },
    handleJobReport(jobID) {
      this.isDownloadingReport = true;
      this.downloadJobReport(jobID).then(() => {
        this.isDownloadingReport = false;
      }).catch(err => {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          title: err,
          showConfirmButton: false,
          timer: 800
        });
      })
    }
  }
});
</script>

<style scoped>
@media only screen and (max-width: 400px) {
  .job-status-button {
    width: 60%;
    margin: 1rem 0;
  }
}

.static-button-height {
  height: calc(2.5rem + 2px) !important;
}
.btn-cyan {
  background-color: #0dcaf0;
  color: white;
  border-color: #0dcaf0;
}
@media only screen and (min-width: 992px) and (max-width: 1253px) {
  .margin-custom {
    margin-top: 0.75rem !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1023px) {
  .margin-custom-mail-button {
    margin-top: 0.75rem !important;
    margin-right: 0 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 958px) {
  .margin-custom {
    margin-top: 0.75rem !important;
  }
}
@media only screen and (max-width: 576px) {
  .mobile-width-control {
    width: 160px !important;
  }
}
.min-w-115px {
  min-width: 115px;
}
/*.job-name-div {*/
/*  max-width: 12rem;*/
/*}*/
</style>

<template>
  <div class="btn-group w-100 w-sm-auto align-items-center">
    <button
      type="button"
      style="white-space: nowrap"
      class="btn btn-sm w-100 w-sm-auto dropdown-toggle btn-warning"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      Download Roster
    </button>
    <ul class="dropdown-menu">
      <li @click="performAction('forThisJob')">
        <span class="dropdown-item" role="button">For this job</span>
      </li>
      <li @click="performAction('forClient')">
        <span class="dropdown-item" role="button">For client</span>
      </li>
    </ul>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "DownloadRoster",
  props: {
    jobIds: {
      type: Array,
      required: false
    }
  },
  methods: {
    ...mapActions('RosterModule', ['downloadRosterFile']),
    performAction(action) {
      if (action === 'forThisJob') {
        this.downloadRosterFile({
          jobs: this.jobIds,
          client: null
        });
      } else if (action === 'forClient') {
        this.$emit('open-client-modal');
      }
    }
  }
});
</script>

<style scoped>
</style>
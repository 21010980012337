<template>
  <div>
    <DownloadCrewListModal ref="crewListModal" />
    <SendMailModal ref="sendMailModal" />
    <div class="btn-group w-100 w-sm-auto align-items-center">
      <button
        type="button"
        style="white-space: nowrap"
        class="btn btn-sm w-100 w-sm-auto min-w-100px dropdown-toggle btn-primary"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Crew List
      </button>
      <ul class="dropdown-menu">
        <li
          v-if="$can('can_download_crewlist_pdf', 'all')"
          @click="performAction('download')"
        >
          <span class="dropdown-item" role="button">Download</span>
        </li>
        <li
          v-if="$can('can_send_crewlist', 'all')"
          @click="performAction('send')"
        >
          <span class="dropdown-item" role="button">Send</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import DownloadCrewListModal from "@/components/modals/DownloadCrewListModal.vue";
import SendMailModal from "@/components/jobs/Misc/SendMailModal.vue";

export default defineComponent({
  name: "CrewListActions",
  components: {SendMailModal, DownloadCrewListModal},
  methods: {
    performAction(action) {
      if (action === 'download') {
        this.openCrewListModal();
      } else if (action === 'send') {
        this.openSendMailModal();
      }
    },
    openCrewListModal() {
      this.$refs.crewListModal.toggleModal(true);
    },
    openSendMailModal() {
      this.$refs.sendMailModal.toggleModal(true);
    }
  }
});
</script>

<style scoped></style>

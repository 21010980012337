
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { Modal } from "bootstrap";
import { useStore } from "vuex";

export interface ShiftSelectItem {
  id: number;
  label: string;
}

export interface Shift {
  id: number;
  label: string;
}

export interface Job {
  shifts: Shift[];
}

export default defineComponent({
  name: "download-crew-list-modal",
  emits: ["download-crew-list"],
  setup: function() {
    const rawElement = ref<HTMLElement | null>(null);
    const modal = ref<Modal | null>(null);
    const selectedShifts = ref<number[]>([]);
    const store = useStore();
    const loading = ref<boolean>(false);

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    const shifts = computed<ShiftSelectItem[]>((): ShiftSelectItem[] => {
      const data = store.getters["JobModule/item"] as Job;
      const items = [] as ShiftSelectItem[];
      if (data) {
        data.shifts.forEach((shift: Shift) => {
          items.push({
            label: shift.label,
            id: shift.id
          });
        });
      }
      return items;
    });

    function clearState(): void {
      selectedShifts.value = [];
      setLoading(false);
    }

    function toggleModal(state: boolean): void {
      if (modal.value) {
        if (state) {
          modal.value.show();
        } else {
          modal.value.hide();
        }
      }
    }

    onMounted((): void => {
      rawElement.value = document.getElementById("download_crew_list_modal");
      if (rawElement.value) {
        modal.value = new Modal(rawElement.value);
        rawElement.value.addEventListener("hidden.bs.modal", clearState);
      }
    });

    onUnmounted((): void => {
      if (rawElement.value) {
        rawElement.value.removeEventListener("hidden.bs.modal", clearState);
      }
    });

    function handleDownload(): void {
      setLoading(true);
      store
        .dispatch("JobModule/downloadCrewlistPDF", selectedShifts.value)
        .then(() => {
          toggleModal(false);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    return {
      handleDownload,
      selectedShifts,
      toggleModal,
      loading,
      shifts
    };
  }
});

<template>
  <div :class="`alert alert-${type} d-flex align-items-center p-6 border-0`">
    <div :class="`${iconContainerClass} d-flex justify-content-center align-items-center alert-icon me-7`">
      <slot name="icon"></slot>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "primary",
    },
    iconContainerClass: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "This is an alert",
    },
  }
};
</script>

<style scoped>
.alert-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: white;
  padding: 10px;
}

.alert-icon i {
  font-size: 1.6rem;
}
</style>

export const upcomingJobTypes = ["Cancelled", "Published", "Draft"];
export const pastJobTypes = [
  "Report!",
  "Cancelled",
  "Reported",
  "Approved",
  "Invoiced",
  "Paid"
];

export const PUBLISHED = "Published";
export const AWAITINGREPORT = "Report!";


<template>
  <div
    v-if="$can('job_status_change', 'all')"
    :class="`btn-group ${fullWidth ? 'w-100' : ''} align-items-center`"
  >
    <button
      :disabled="jobStatusLoading.loading && jobStatusLoading.job_id == job.id"
      type="button"
      style="white-space: nowrap"
      class="btn btn-sm dropdown-toggle"
      :class="buttonClass"
      :data-kt-indicator="
        jobStatusLoading.loading && jobStatusLoading.job_id == job.id
          ? 'on'
          : 'off'
      "
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span class="indicator-label align-middle ms-2">{{
        currentJobStatus
      }}</span>
      <span class="indicator-progress align-middle">
        <span class="spinner-border spinner-border-sm"></span>
        {{ currentJobStatus }}
      </span>
    </button>
    <ul class="dropdown-menu">
      <li
        v-for="(job, index) in possibleJobTypes"
        :key="index"
        @click="changeJobStatus(job)"
      >
        <span class="dropdown-item" role="button">{{ job }}</span>
      </li>
    </ul>
  </div>
  <div
    v-else-if="$can('job_status_view', 'all')"
    :class="`btn-group ${fullWidth ? 'w-100' : ''} align-items-center`"
  >
    <button
      type="button"
      style="white-space: nowrap; pointer-events: none;"
      class="btn btn-sm"
      :class="buttonClass"
    >
      {{ currentJobStatus }}
    </button>
  </div>
</template>

<script lang="js">
import { defineComponent } from "vue";
import {mapActions, mapMutations, mapGetters} from "vuex";
import { PUBLISHED , AWAITINGREPORT , upcomingJobTypes, pastJobTypes} from "@/core/helpers/job_status.js";

export default defineComponent({
  name: "Job-Status-Button",
  props: {
    job: Object,
    jobsStatusFilter: String,
    fullWidth: {
      type: Boolean,
      default: true
    },
    isSingleJobView: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions("DashboardModule", ["saveNewJobStatus", "setIsChangingStatus"]),
    ...mapActions("JobModule", ["updateAfterStatusChange"]),
    ...mapMutations("JobModule", ["setStatus"]),
    changeJobStatus(newJobStatus) {
      const payload = {
        id: this.job.id,
        newStatus: newJobStatus,
        isSingleJobView: this.isSingleJobView
      };
      this.saveNewJobStatus(payload)
        .then(() => {
          // If we are on a single job view and want to edit job status, we do that by hitting a setStatus mutation in JobModule.
          // Else if we're editing job status from dashboard, we do that by finding the job by id and editing the status
          // in DashboardModule.
          if (this.isSingleJobView) {
            this.updateAfterStatusChange()
            .then(() => {
              this.setStatus(newJobStatus);
              this.setIsChangingStatus(this.job.id, false);
            });
          }
        });
    }
  },
  computed: {
    ...mapGetters("DashboardModule", ["jobStatusLoading"]),
    currentJobStatus() {
      if (
        this.jobsStatusFilter == "upcoming" &&
        !upcomingJobTypes.includes(this.job.status)
      ) {
        return PUBLISHED;
      } else if (
        this.jobsStatusFilter == "past" &&
        this.job.is_past &&
        !pastJobTypes.includes(this.job.status)
      ) {
        return AWAITINGREPORT;
      } else {
        return this.job.status;
      }
    },
    possibleJobTypes() {
      if (this.jobsStatusFilter == "upcoming" || !this.job.is_past) {
        return upcomingJobTypes.filter(job => job !== this.currentJobStatus);
      } else {
        return pastJobTypes.filter(job => job !== this.currentJobStatus);
      }
    },
    buttonClass() {
      switch(this.currentJobStatus){
        case "Published":
          return "btn-primary";
        case "Report!":
          return "btn-danger";
        case "Draft":
          return "btn-light";
        case "Paid":
          return "btn-success";
        case "Invoiced":
          return "btn-dark";
        case "Approved":
          return "btn-cyan text-white";
      }
      return "btn-warning"
    }
  }
});
</script>

<style scoped>
.btn-cyan {
  background-color: #0dcaf0;
}
</style>

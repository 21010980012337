
import MissingRatesModal from "@/components/payments/misc/MissingRatesModal.vue";
import {
  computed,
  defineComponent,
  nextTick, onMounted,
  PropType,
  ref
} from "vue";
import * as helpers from "@/components/payments/misc/helpers";
import { Modal } from "bootstrap";
import { useStore } from "vuex";
import Swal from "sweetalert2";

export default defineComponent({
  components: { MissingRatesModal },
  props: {
    jobId: {
      type: Number as PropType<number>,
      default: () => 0
    },
    minWidth: {
      type: Number as PropType<number>,
      default: () => 116
    }
  },
  setup: function(props) {
    const store = useStore();
    const loading = ref<boolean>(false);
    const modalkey = ref<number>(0);
    const missingRates = ref({});
    const modal = ref();
    const branches = computed(() => store.getters["ListsModule/branches"]);

    onMounted((): void => {
      if(branches.value.length === 0) {
        store.dispatch("ListsModule/fetchBranches");
      }
    })

    function setModalState(error, userId) {
      missingRates.value = error;
    }

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    function showMissingRatesModal(err) {
      setModalState(err.rates, null);
      modalkey.value++;
      nextTick().then(() => {
        modal.value = new Modal(document.getElementById("missing-rates-modal"));
        modal.value.show();
      });
    }

    function downloadWithZeroRates() {
      setLoading(true);
      store
        .dispatch("JobModule/downloadJobSalaryReport", true)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }

    function handleDownloadSalaryReportForJob(): void {
      if (props.jobId === 0) {
        return;
      }
      setLoading(true);
      store
        .dispatch("JobModule/downloadJobSalaryReport", false)
        .then(() => {
          setLoading(false);
        })
        .catch(err => {
          if (err.type && err.type === "MissingRates") {
            Swal.fire({
              ...helpers.MissingRatesSwalOptions
            }).then(result => {
              if (result.isConfirmed) {
                showMissingRatesModal(err);
              } else {
                downloadWithZeroRates();
              }
            });
          }
          setLoading(false);
        });
    }

    function handleSaveRates() {
      handleDownloadSalaryReportForJob();
      modal.value.hide();
    }

    return {
      handleDownloadSalaryReportForJob,
      handleSaveRates,
      missingRates,
      modalkey,
      loading
    };
  }
});

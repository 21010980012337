
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const value = ref("");
    return {
      value
    };
  }
});

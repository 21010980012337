
import {computed, defineComponent, nextTick, onMounted, reactive, ref} from "vue";
import MultiMailInput from "@/components/jobs/Misc/MultiMailInput.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "send-mail-modal",
  components: {
    MultiMailInput
  },
  setup(props, { emit }) {
    const renderKey = ref<number>(0);
    const store = useStore();
    const loading = ref<boolean>(false);
    const job = computed(() => store.getters["JobModule/item"]);
    const element = ref<HTMLElement | null>(null);
    const modal = ref<Modal | null>(null);
    const data = reactive({
      jobID: job.value.id,
      sendToCol: false,
      sendToPM: false,
      emails: [{ mail: "" }]
    });
    const error = ref<string | null>(null);
    function hasOneEmptyEntry() {
      if (data.emails.length == 1 && data.emails[0].mail == "") {
        error.value = null;
        return true;
      }
      return false;
    }
    function deleteNullMails() {
      for (let i = 0; i < data.emails.length; i++) {
        if (data.emails[i].mail == "" && i != 0) data.emails.splice(i, 1);
      }
    }

    onMounted((): void => {
      element.value = document.getElementById("send_mail_modal");
      if (element.value) {
        modal.value = new Modal(element.value);
        element.value.addEventListener("hidden.bs.modal", () => {
          renderKey.value++;
          data.sendToCol = false;
          data.sendToPM = false;
          data.emails = [{ mail: "" }];
          error.value = null;
        });
      }
    });

    function toggleModal(value: boolean): void {
      if (modal.value) {
        if (value) {
          modal.value?.show();
        } else {
          modal.value.hide();
        }
      }
    }

    function sendMail() {
      loading.value = true;
      deleteNullMails();
      store
        .dispatch("JobModule/SendMailToColAndPM", data)
        .then(() => {
          loading.value = false;
          emit("success");
        })
        .catch(() => {
          loading.value = false;
        });
    }
    function handleSubmit() {
      if (hasOneEmptyEntry() && !(data.sendToCol || data.sendToPM)) {
        loading.value = true;
        Swal.fire({
          toast: true,
          position: "top-right",
          title: "Please, select at least one recipient",
          icon: "error",
          showConfirmButton: false,
          timer: 1000
        }).then(() => {
          loading.value = false;
        });
        return;
      }
      if (!error.value) {
        if (job.value.has_send_crewlist) {
          Swal.fire({
            title: "Are you sure?",
            text: "Crew list has  already been sent",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
          }).then(result => {
            if (result.isConfirmed) {
              sendMail();
            }
          });
        } else {
          sendMail();
        }
      }
    }

    function isValid(email) {
      const regExp = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
      if (email.match(regExp)) {
        return true;
      }
      return false;
    }
    function exists(email, index) {
      for (let i = 0; i < data.emails.length; i++) {
        if (data.emails[i].mail == email.trim() && i != index) return true;
      }
      return false;
    }
    function hasError(email, index) {
      if (email == "") {
        error.value = "Email cannot be empty";
        return true;
      }
      if (exists(email, index)) {
        error.value = "Email already exists";
        return true;
      }

      return false;
    }
    function handleInput(input, index) {
      data.emails[index].mail = input;
      if (exists(input, index)) {
        error.value = "Email already exists";
      } else if (!isValid(input) && input != "") {
        error.value = "Needs to be a valid email";
      } else {
        error.value = null;
      }
    }
    function addNewMail(index) {
      if (!hasError(data.emails[index].mail, index) && !error.value) {
        data.emails.push({ mail: "" });
      } else {
        setTimeout(() => {
          hasOneEmptyEntry();
          if (data.emails[index].mail == "") error.value = null;
        }, 1300);
      }
    }
    function deleteMail(index) {
      data.emails.splice(index, 1);
      if (hasOneEmptyEntry()) {
        error.value = null;
      }
    }

    return {
      handleSubmit,
      renderKey,
      toggleModal,
      deleteMail,
      addNewMail,
      handleInput,
      error,
      loading,
      data,
      job
    };
  }
});

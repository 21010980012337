<template>
  <LoadingSpinner v-if="loading"></LoadingSpinner>
  <JobShiftsCard v-else></JobShiftsCard>
  <router-view v-if="!loading && item != null"></router-view>
</template>

<script lang="js">
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { mapActions, mapGetters } from "vuex";
import JobShiftsCard from "@/components/jobs/JobShiftsCard.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue"

export default defineComponent({
  name: "job-view",
  components: {
    JobShiftsCard,
    LoadingSpinner
  },
  mounted() {
    this.fetchOne(this.$route.params.id);
    setCurrentPageTitle("Job View");
  },
  methods: {
    ...mapActions("JobModule", ["fetchOne",])
  },
  computed: {
    ...mapGetters("JobModule", ["loading", "item"])
  }
});
</script>


import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { Modal } from "bootstrap";
import * as Yup from "yup";
import { useStore } from "vuex";
import { fireToast } from "@/core/helpers/swal";
import moment from "moment";

export default defineComponent({
  name: "generate-crew-roster-modal",
  components: {
    Form,
    Field,
    ErrorMessage
  },
  setup: function() {
    const element = ref<HTMLElement | null>(null);
    const store = useStore();
    const modal = ref<Modal | null>(null);
    const clientId = ref<number | null>(null);
    const formComponent = ref();
    const selectedDate = ref<string[] | null>(null);
    const loading = ref<boolean>(false);

    function toggleModal(toggle: boolean, client: number | null): void {
      if (modal.value) {
        if (toggle) {
          if (client) clientId.value = client;
          modal.value.show();
        } else {
          modal.value.hide();
        }
      }
    }

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    const validationSchema = Yup.object().shape({
      date: Yup.array()
        .typeError("Period is required")
        .required("Period is required")
    });

    function clearClient(): void {
      clientId.value = null;
      selectedDate.value = null;
      formComponent.value?.resetForm();
    }

    onMounted(() => {
      element.value = document.getElementById("generate-crew-roster-modal");
      if (element.value) {
        modal.value = new Modal(element.value);
        element.value.addEventListener("hidden.bs.modal", clearClient);
      }
    });

    onUnmounted(() => {
      if (element.value)
        element.value.removeEventListener("hidden.bs.modal", clearClient);
    });

    function handleSubmit(): void {
      setLoading(true);
      if (!selectedDate.value) return;
      const startDate = moment(selectedDate.value[0]).format("DD/MM/YYYY");
      const endDate = moment(selectedDate.value[1]).format("DD/MM/YYYY");
      store
        .dispatch("RosterModule/downloadRosterFile", {
          client: clientId.value,
          startDate: startDate,
          endDate: endDate
        })
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          fireToast(err, false);
        });
    }

    return {
      validationSchema,
      formComponent,
      selectedDate,
      handleSubmit,
      toggleModal,
      loading,
      modal
    };
  }
});
